import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as ContentNodeType } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentNode, ContentTitle, useEntity, useLang, CellLabel } from '@shapeable/ui';
import { Organisation } from '@shapeable/kelp-forest-alliance-types';
import PLEDGE_BADGE from '../../images/pledge-badge.webp';
import { OrganisationPledgeValue } from './organisation-pledge-value';
import { classNames } from '@shapeable/utils';
const cls = classNames('orgnisation-pledges-aside');

// -------- Types -------->

export type OrgnisationPledgesAsideProps = Classable & HasChildren & { 
  entity?: Organisation;
};

export const OrgnisationPledgesAsideDefaultProps: Omit<OrgnisationPledgesAsideProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
    margin=left: 1px;
  `,
});

const BadgeStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
    width: 100%;
    max-width: 400px;
  `,
});

const BlurbStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const PledgesStyles = breakpoints({
  base: css`
  `,
});

const PledgesHeaderStyles = breakpoints({
  base: css`
    margin-left: 1px;
    margin-bottom: ${theme.UNIT(2)};
  `,
});
const ValueStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
    margin-right: ${theme.UNIT(3)};
    width: auto;
  `,
});

const ValueDescriptionStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(13)};
    margin-bottom: ${theme.UNIT(2)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Header: styled(ContentTitle)`${HeaderStyles}`,
    Badge: styled.img`${BadgeStyles}`,
    Blurb: styled(ContentNode)`${BlurbStyles}`,

  Pledges: styled.div`${PledgesStyles}`,
    PledgesHeader: styled(CellLabel)`${PledgesHeaderStyles}`,
      Value: styled(OrganisationPledgeValue)`${ValueStyles}`,
      ValueDescription: styled(ContentNode)`${ValueDescriptionStyles}`,
};

export const OrgnisationPledgesAside: Shapeable.FC<OrgnisationPledgesAsideProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { pledges = [], description } = entity
  
  const t = useLang()

  const blurbText = `This organisation has pledged toward the [Kelp Forest Challenge](/kelp-forest-challenge)`;

  const blurb: ContentNodeType = {
    text: blurbText,
  };

  return (
   <My.Container className={cls.name(className)}>
    <My.Header>{t('Kelp Forest Challenge:')}</My.Header>
      <My.Badge src={PLEDGE_BADGE} />
      <My.Blurb entity={blurb} />

      <My.Pledges>
        <My.PledgesHeader>{t('Pledges:')}</My.PledgesHeader>
        {
          pledges.map(pledge => (
            <>
              <My.ValueDescription entity={pledge?.description} />
              <My.Value entity={pledge} />
            </>
          ))
        }
      </My.Pledges>

   </My.Container>
  )
};

OrgnisationPledgesAside.defaultProps = OrgnisationPledgesAsideDefaultProps;
OrgnisationPledgesAside.cls = cls;