import { graphql } from 'gatsby';
import { OrganisationLayout } from '../../components/entities/organisation-layout';
import { createGatsbyPageComponent } from '@shapeable/ui';
export default createGatsbyPageComponent('Organisation', { layout: OrganisationLayout });

export const query = graphql`
  query OrganisationQuery($id: ID!) {
    platform {
      organisation(id: $id) {
        __typename _schema { label pluralLabel }
        openGraph { description { text(truncate: { words: 20 }) } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
        id slug path name
        latitude longitude
        name
        url
        logo {
          url url2x
        }
        description {
          id
          text
        }
        people {
          id slug path
          name
        }
        pledges {
          id value name date
          description { text } 
          latitude longitude
          metric { 
            id slug name title 
            unit { 
              id shorthand name 
            }
          }
        }
        location {
          __typename id slug name
          latitude longitude
          openGraph {
            id
            image {
              id
              url url2x
            }
          }
        }
        type {
          id
          name
          openGraph {
            image {
              id
              url url2x
            }
          }
        }
        primaryContact {
          id slug path name
          openGraph {
            image {
              url url2x
            }
          }
        }
        methodologies {
          __typename id slug path name
          openGraph {
            id title description {
              id text
            }
            image {
              url url2x
            }
          }
        }
        species {
          __typename id slug path name
          openGraph {
            id title description {
              id text
            }
            image {
              url url2x
            }
          }
        }
        video {
          __typename id name slug url
        openGraph { 
            id title 
            image { 
              id url url2x
            } 
          } 
        }
        pledges {
          __typename id name slug
          metric {
            title
            unit {
              name
            }
          }
          openGraph {
            id title
          }
        }
          restorationProjects {
            __typename id name path slug
            place {
              name
            }
            openGraph {
              image {
                url url2x
              }
            }
            openGraph {
              description {
                text
              }
            }
            species {
              id name path
              openGraph {
                id
                title
                description { id text }
              }
            }
            openGraph {
              id
              title
              description { id text }
            }
            methodologies {
              id name path
              openGraph {
                id 
                title
                description {id text}
              }
            }
          }
        }
      }
    }
`;