import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  useEntity, MainAside, EntityValue, ContentNode, useLang, EntityListSection, 
  EntityMultiValueList, PlainLinkButton, Video, EntityLinkValue, 
  SliceLayoutBoundary, 
  PageHeader
} from '@shapeable/ui';
import { OrganisationHeaderLayout } from './organisation-header-layout';
import { BANNER_KNOWLEDGE_HUB, BANNER_OVERLAY } from '../../data';
import { Organisation } from '@shapeable/kelp-forest-alliance-types';
import { MapPinIcon } from '../elements/map-pin-icon';
import { OrgnisationPledgesAside } from './organisation-pledges-aside'
import { classNames, locationDMS } from '@shapeable/utils';
import { useMapUtils } from '@shapeable/maps';
import { LocationIcon, OrganisationTypeIcon } from '@shapeable/icons';

const cls = classNames('organisation-layout');

// -------- Types -------->

export type OrganisationLayoutProps = Classable & HasChildren & { 
  entity?: Organisation;
};

export const OrganisationLayoutDefaultProps: Omit<OrganisationLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    h1.shp--content-title {
      font-size: 1.2em;
      text-transform: none;
      margin-bottom: ${theme.UNIT(2)};
    }

  `,
});

  const HeaderStyles = breakpoints({
    base: css`
    `,
  });

  const DMSStyles = breakpoints({
    base: css`
      margin: ${theme.UNIT(2)} 0 ${theme.UNIT(4)};
      ${theme.H_DOT_BG_END()};
      width: 100%;
      padding-bottom: ${theme.UNIT(3)};
      color: ${theme.COLOR('strong')};
      display: flex;
      font-size: ${theme.FONT_SIZE(13)};
      text-align: left;
      align-items: center;
  
      div {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
  
      span, b {
        display: block;
      }
  
      b {
        padding-top: ${theme.UNIT(1)};
        font-weight:500;
      }
  
      &:hover {
        .shp--map-marker-icon__fill {
          fill: ${theme.COLOR('link-hover')};
        }
      }
    `,
    tablet: css`
      font-size: ${theme.FONT_SIZE(15)};
    `
  });

  const DMSIconStyles = breakpoints({
    base: css`
      margin-right: ${theme.UNIT(1)};
      width: 56px;
      height: 56px;
    `,
  });

  const BodyStyles = breakpoints({
    base: css`
      width: 100%;

    `,
  });

  const MainAsideStyles = breakpoints({
    base: css`
      font-size: ${theme.FONT_SIZE(15)};
      padding-bottom: ${theme.UNIT(6)};
    `,
    tablet: css`
    `,
  });

  const AsideStyles = breakpoints({
    base: css`
      padding-top: ${theme.UNIT(4)};
      
    `,
    desktop: css`
    `,
  });

  const AsideItemStyles = breakpoints({
    base: css`
      ${theme.H_DOT_BG_END()};
      margin-bottom: ${theme.UNIT(4)};
      padding-bottom: ${theme.UNIT(4)};
    `,
  });

  const OrgTypeStyles = breakpoints({
    base: css`
      ${AsideItemStyles};
    `,
  });

  const PrimaryContactStyles = breakpoints({
    base: css`
     ${AsideItemStyles};
    `,
  });

  const OrganisationLocationStyles = breakpoints({
    base: css`
      ${AsideItemStyles};
    `,
  });
  
  const LocationStyles = breakpoints({
    base: css`
      cursor: pointer;
      color: ${theme.COLOR('dark')};
      &:hover {
        color: ${theme.COLOR('link-hover')};
        }
    `,
  });

  const SpeciesStyles = breakpoints({
    base: css`
      padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
      margin-right: ${theme.UNIT(2)};
    `
  })

  const MethodologiesStyles = breakpoints({
    base: css`
      padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
      margin-right: ${theme.UNIT(2)};
    `
  })

  const ContentStyles = breakpoints({
    base: css`
      padding: ${theme.UNIT(6)} 0 ${theme.UNIT(7)};
      ${theme.H_DOT_BG()};
      &:first-child { background: none; }
  
      h1 {
        color: ${theme.COLOR('secondary')};
        text-transform: uppercase;
        margin-bottom: 1em;
      }
    `,
  });

  const AboutStyles = breakpoints({
    base: css`
      ${ContentStyles};
      ${theme.H_DOT_BG()};
    `
  })

  const RestorationProjectStyles = breakpoints({
    base: css`
      ${ContentStyles};
      padding: 0px;
      margin-top: ${theme.UNIT(10)};
      cursor: pointer;
      a {
        color: ${theme.COLOR('strong')};
        &:hover {
          color: ${theme.COLOR('link-hover')};
          }
      }
        `,
    tablet: css`
      ${ContentStyles};
    `
  });

  const VideoStyles = breakpoints({
    base: css`
    ${AsideItemStyles};
    `
  })
  
  const PledgeStyles = breakpoints({
    base: css`
      ${AsideItemStyles};
    `,
  });

  const MobileDescriptionStyles = breakpoints({
    base: css`
      display: flex;
    `,
    tablet: css`
      display: none;
    `,
  });

  const DesktopDescriptionStyles = breakpoints({
    base: css`
      display: none;
    `,
    tablet: css`
      display: flex;
    `,

  });
  
  
// -------- Components -------->

const My = {

  Header: styled(OrganisationHeaderLayout)`${HeaderStyles}`,

  DMS: styled(PlainLinkButton)`${DMSStyles}`,
    DMSIcon: styled(MapPinIcon)`${DMSIconStyles}`,

  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  MainAside: styled(MainAside)`${MainAsideStyles}`,
  Aside: styled.div`${AsideStyles}`,
    Pledge: styled(OrgnisationPledgesAside)`${PledgeStyles}`,
    Video: styled(Video)`${VideoStyles}`,
    OrganisationType: styled(EntityValue)`${OrgTypeStyles}`,
    PrimaryContact: styled(EntityValue)`${PrimaryContactStyles}`,
    OrganisationLocation: styled.div`${OrganisationLocationStyles}`,
    Location: styled(EntityLinkValue)`${LocationStyles}`,
    Species: styled(EntityMultiValueList)`${SpeciesStyles}`,
    Methodologies: styled(EntityMultiValueList)`${MethodologiesStyles}`,

  About: styled(ContentNode)`${AboutStyles}`,
  MobileDescription: styled.div`${MobileDescriptionStyles}`,
  DesktopDescription: styled.div`${DesktopDescriptionStyles}`,
    RestorationProjects: styled(EntityListSection)`${RestorationProjectStyles}`
  
};

export const OrganisationLayout: Shapeable.FC<OrganisationLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { description, type, primaryContact, location, species, methodologies, 
    restorationProjects = [], video, pledges = [], url, openGraph = {}, } = entity

  const t = useLang()

  const dms = locationDMS(entity);
  const { showEntity } = useMapUtils();

  const dmsOnClick = () => {
    showEntity(entity, {
      showMap: true,
      entityTypeNames: ['Organisation'],
      zoom: 11,
    });
  };

  const pageEntity: Page = {
    id: `page-${entity.id}`,
    banners: [BANNER_KNOWLEDGE_HUB],
  };

  return (
   <My.Container className={cls.name(className)}>
      <PageHeader aspectRatio={{ base: 4 / 1, desktop: 3, desktopLarge: 4, }} overlayColor={BANNER_OVERLAY} entity={pageEntity}>
        <My.Header entity={entity} />
      </PageHeader>
      <SliceLayoutBoundary>

        <My.Body>
          <My.MainAside 
            aside={
              <My.Aside>
                {
                  dms &&
                  <My.DMS onClick={dmsOnClick}>
                    <My.DMSIcon />
                    <div>
                      <span>{t('View on Map')}</span>
                    <b>{dms}</b>
                    </div>
                  </My.DMS>
                }
                {
                  !!pledges.length && 
                  <My.Pledge entity={entity}/>
                }
                {
                  video && 
                      <My.Video entity={video} />
                }
                {
                  type && <My.OrganisationType label="Organisation Type" entity={{
                    ...type, 
                    __icon: OrganisationTypeIcon 
                  }} />
                }
                {
                  primaryContact && <My.PrimaryContact label="Primary Contact" entity={primaryContact} />
                }
                {
                  location && 
                    <My.OrganisationLocation>
                      <My.Location entity={{
                        ...location,
                        __icon: LocationIcon
                      }} />
                    </My.OrganisationLocation>
                }
                {
                  species && <My.Species background="bright" items={species} />
                }
                {
                  methodologies && <My.Methodologies background="bright" items={methodologies} />
                }
                <My.MobileDescription>
                  <My.RestorationProjects showDescription useLinks items={restorationProjects} namePath="place.name"/>
                </My.MobileDescription>
                
              </My.Aside>
            }>
                <My.About title={t('About')} entity={description} />

                <My.DesktopDescription>
                  <My.RestorationProjects title={t('Projects:')} showDescription useLinks items={restorationProjects} namePath="place.name" />
                </My.DesktopDescription>

          </My.MainAside>   
        </My.Body>
      </SliceLayoutBoundary>
   </My.Container>
  )
};



OrganisationLayout.defaultProps = OrganisationLayoutDefaultProps;
OrganisationLayout.cls = cls;